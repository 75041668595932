.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.sub-board {
  background: #fff;
  border: 1px solid #999;
  float: left;
  margin-right: -1px;
  margin-top: -1px;
  padding: 10px;
  position: relative;
  text-align: center;
}

.meta-board {
  position: relative;
  text-align: center;
}

.meta-winner-overlay {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -270px;
  font-size: 350px;
  z-index: 10;
  pointer-events: none;
}

.sub-winner-overlay {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -70px;
  font-size: 90px;
  z-index: 5;
}

.red {
  color: #800;
}

.disabled {
  background: #000;
  opacity: 0.15;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
  text-align: center;
  min-width: 363px;
  padding-top: 1em;
}

.next-move {
  float: left;
}
